import ExamesQuery from '@/queryObjects/ExamesQuery';
import { Get, GetAll, Post, Put } from './BaseServiceID';
import ExamInfoDTO from '@/dtos/ExamInfoDTO';
import ExamAnswerDTO from '@/dtos/ExamAnswerDTO';
import ExamResponseDTO from '@/dtos/ExamResponseDTO';
import ProctoringResponseDTO from '@/dtos/ProctoringResponseDTO';
import ExamRequestDTO from '@/dtos/ExamRequestDTO';
import ExamRequestFinalDTO from '@/dtos/ExamRequestFinalDTO';
import ConfigurationResponseDTO from '@/dtos/ConfigurationResponseDTO';
import ExamsQuery from '@/queryObjects/ExamsQuery';
import vm from "@/viewModels/MainViewModel";
import { ActiveMonitoringDTO } from '@/dtos/ActiveMonitoringDTO';
import { ConfigDTO } from '@/dtos/ConfigsDTO';
import { ProcessingDTO } from '@/dtos/ProcessingDTO';
import { RulesDTO } from '@/dtos/RulesDTO';

export default {
    GetExames:(query: ExamesQuery) => {
        return Get<ExamInfoDTO[]>('api/Exam/', query);
    },

    GetExameAnswer:(agendamentoId:string) => {
        // console.log('agendamentoId:',agendamentoId);
        return Get<ExamAnswerDTO>(`api/ExamAnswer/${agendamentoId}`);
    },

    GetReprocessing:(proctoringId:string) => {
        return Post<any, any>(`api/Proctoring/reprocess/${proctoringId}`, '');
    },

    GetExams:(query: ExamsQuery) => {
        if(query.order == 0)
        {
            query.order = undefined;
            query.orderByAlertCount = true;
        }
        if(query.onlyFraud != undefined && !query.onlyFraud)
            query.onlyFraud = undefined;

        const exam = GetAll<ProctoringResponseDTO[]>(`api/Proctoring/`, query);
        return exam;
    },

    GetExame:(examId:string) => {
        // console.log('examId:',examId);
        const exam = Get<ProctoringResponseDTO>(`api/Proctoring/${examId}`);
        // console.log(exam);
        return exam;
    },

    UpdateExame:async (examId:string, examResponseDTO: ExamResponseDTO) => {
        // console.log('examResponseDTO', examResponseDTO);
        // console.log('update');
        return await Put<ExamResponseDTO, ExamResponseDTO>(`api/Exam/${examId}`, examResponseDTO );
    },

    CreateExame:(examRequestDTO: ExamRequestDTO) => {
        // console.log('post create', examRequestDTO);
        const startDateNew = new Date(examRequestDTO.startDate);
        startDateNew.setHours(examRequestDTO.startDate.getHours() - 3);

        const endDateNew = new Date(examRequestDTO.endDate);
        endDateNew.setHours(examRequestDTO.endDate.getHours() - 3);

        const request:ExamRequestFinalDTO = {
            ... examRequestDTO,
            startDate: startDateNew.toISOString(),
            endDate: endDateNew.toISOString(),
            sendDateLimit: examRequestDTO.sendDateLimit.toISOString(),
        };
        // console.log('post create reqeust', request);

        return Post<ExamRequestFinalDTO, ExamResponseDTO>(`api/Exam`, request);
    },

    GetConfiguration:() => {
        return Get<ConfigurationResponseDTO[]>(`api/ExamConfiguration`);
    },

    GetConfigs:() => {
        const configs = Get<ConfigDTO>(`api/Client/params`);
        return configs;
    },

    GetRules:() => {
        const configs = Get<RulesDTO[]>(`api/Client/rules`);
        return configs;
    },
    // Active Monitoring
    PostActiveMonitoring:(activeMonitoringDTO: ActiveMonitoringDTO) => {
        const configs = Post<ActiveMonitoringDTO, ActiveMonitoringDTO>(`api/Client/params`, activeMonitoringDTO);
        return configs;
    },
    // Processing
    PostProcessing:(processingDTO: ProcessingDTO) => {
        const configs = Post<ProcessingDTO, ProcessingDTO>(`api/Client/image-sensibility`, processingDTO);
        return configs;
    },
    // Rules
    PostRules:(rules: RulesDTO[]) => {
        const configs = Post<RulesDTO[], RulesDTO[]>(`api/Client/rules`, rules);
        return configs;
    },

    SaveExame:(examRequestDTO: ExamRequestDTO) => {
        // console.log('post create', examRequestDTO);


        let startDateNew = new Date(examRequestDTO.startDate);
        const userTimezoneOffset = startDateNew.getTimezoneOffset() * 60000;
        startDateNew = new Date(startDateNew.getTime() - userTimezoneOffset);

        let endDateNew = new Date(examRequestDTO.endDate.toUTCString());

        const userTimezoneOffset_ = endDateNew.getTimezoneOffset() * 60000;
        endDateNew = new Date(endDateNew.getTime() - userTimezoneOffset_);

        let sendDateLimitNew = new Date(examRequestDTO.sendDateLimit.toUTCString());

        const userTimezoneOffset__ = sendDateLimitNew.getTimezoneOffset() * 60000;
        sendDateLimitNew = new Date(sendDateLimitNew.getTime() - userTimezoneOffset__);

        const request:ExamRequestFinalDTO = {
            ... examRequestDTO,
            startDate: startDateNew.toISOString(),
            endDate: endDateNew.toISOString(),
            sendDateLimit: sendDateLimitNew.toISOString(),
        };

        // console.log('post create reqeust', request);
        return Put<ExamRequestFinalDTO, ExamResponseDTO>(`api/Exam/`+examRequestDTO.id, request);
    },
};